import React, { useState, useEffect, useCallback } from "react";
import DynamicSvg from "../../../DynamicSvg/DynamicSvg";
import SectorsDetails from "./SectorsDetails";
import { Button } from "../../../Button/Button";
import { formatEmissions, isNotEmptyObject } from "../../../../utils/common";
import arrow from "../../../../assets/images/arrow.svg";
import incomeImg from "../../../../assets/images/incomeImg.svg";
import {
    matrixClassificationsIncomeText,
    matrixClassificationsText,
    matrixClassificationsIncomeBoxes
} from "../../../../utils/leafletMap.js";
/** @jsxImportSource @emotion/react */
import tw, { theme } from "twin.macro";
import { blockedTransportCountries } from "../../../../utils/countries.js";

let timeout;

const Details = ({
    selectedCountry,
    activeScenario,
    handleSelectedSector,
    subSectors,
    closeDetailsCard,
    cardInfo,
    typeOfMapEmissions,
    emissions_cumulative
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOverviewActive, setIsOverviewActive] = useState(true);

    const showSectorDetails = (currentTarget) => {
        if (
            blockedTransportCountries.includes(selectedCountry.iso3c) &&
            currentTarget.target.name === "Transport"
        )
            return;
        handleSelectedSector(currentTarget);
        timeout = setTimeout(() => setIsOpen(true), 100);
    };

    const hideSectorDetails = useCallback(() => setIsOpen(false), []);

    useEffect(() => {
        setIsOpen(false);
        return () => clearTimeout(timeout);
    }, [selectedCountry.name]);

    const toggleOverview = () => setIsOverviewActive((prevState) => !prevState);

    const handleCumulativeEmissions = () =>
        emissions_cumulative.find(
            ({ iso3c, year }) =>
                iso3c === selectedCountry.iso3c && year === selectedCountry.year
        );

    return (
        <div
            className="scrollable"
            css={[
                isNotEmptyObject(selectedCountry) &&
                    tw`md:max-h-[calc(100vh - 202px)]`
            ]}
        >
            <div tw="flex">
                <button
                    disabled={isOverviewActive}
                    css={[
                        tw`h-[3rem] w-full hover:bg-teal-100 border-gray-100 border-b hover:border-b-2`,
                        isOverviewActive &&
                            tw`border-teal-800 border-b-2 bg-[#F5FDFC] font-semiBold text-teal-800`
                    ]}
                    onClick={toggleOverview}
                >
                    Overview
                </button>
                <button
                    disabled={!isOverviewActive}
                    css={[
                        tw`h-[3rem] w-full hover:bg-teal-100 border-gray-100 border-b hover:border-teal-800 hover:border-b-2`,
                        !isOverviewActive &&
                            tw`border-teal-800 border-b-2 bg-[#F5FDFC] font-semiBold text-teal-800`
                    ]}
                    onClick={toggleOverview}
                >
                    Sectors
                </button>
            </div>
            {isOverviewActive ? (
                <div tw="p-[1rem]">
                    <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] flex flex-col gap-[0.5rem] text-xs text-gray-800">
                        <h3>GHG emissions in C0₂eq {selectedCountry.year}:</h3>
                        <span tw="font-semiBold text-lg text-orange-400">
                            {formatEmissions(cardInfo.emissions, true)}
                        </span>
                    </div>
                    <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] flex flex-col gap-[0.5rem] text-xs text-gray-800 mt-[1rem]">
                        <div tw="flex justify-between">
                            <div tw="flex flex-col gap-[0.5rem]">
                                <h4>Emissions per-person:</h4>
                                <p tw="font-semiBold text-orange-400 text-regular">
                                    {formatEmissions(
                                        cardInfo.emissions_per_person,
                                        true
                                    )}
                                </p>
                            </div>
                            {typeOfMapEmissions === "income" ? (
                                <div tw="flex flex-col gap-[0.25rem]">
                                    <h4>Main emitting sector:</h4>
                                    <div tw="flex items-center gap-[0.5rem]">
                                        <DynamicSvg
                                            iconName={
                                                cardInfo.highest_emissions
                                                    .sector
                                            }
                                            size={24}
                                            fill={`${theme`colors.orange.400`}`}
                                        />
                                        <p tw="font-semiBold text-orange-400 text-regular">
                                            {formatEmissions(
                                                typeOfMapEmissions === "income"
                                                    ? cardInfo
                                                          .highest_emissions[
                                                          activeScenario.id
                                                      ]
                                                    : cardInfo.highest_emissions_per_person,
                                                true
                                            )}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <div tw="flex flex-col gap-[0.5rem]">
                                    <h4>Main emitting sector:</h4>
                                    <div tw="flex items-center gap-[0.5rem]">
                                        <DynamicSvg
                                            iconName={
                                                cardInfo.highest_emissions
                                                    .sector
                                            }
                                            size={24}
                                            fill={`${theme`colors.orange.400`}`}
                                        />
                                        <p tw="font-semiBold text-orange-400 text-regular">
                                            {formatEmissions(
                                                typeOfMapEmissions === "total"
                                                    ? cardInfo
                                                          .highest_emissions[
                                                          activeScenario.id
                                                      ]
                                                    : cardInfo.highest_emissions_per_person,
                                                true
                                            )}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                        {cardInfo.iso3c !== "WLD" && (
                            <p
                                css={[
                                    tw`text-xs p-[0.25rem] rounded-[0.25rem] font-regular max-w-max`,
                                    [
                                        "low_emissions/high_income",
                                        "high_emissions/middle_income",
                                        "high_emissions/high_income"
                                    ].includes(
                                        `${cardInfo.classifications}/${cardInfo.income}`
                                    )
                                        ? tw`text-white`
                                        : tw`text-orange-800`
                                ]}
                                style={{
                                    background:
                                        matrixClassificationsIncomeBoxes.find(
                                            ({ text }) =>
                                                text ===
                                                `${cardInfo.classifications}/${cardInfo.income}`
                                        ).color
                                }}
                            >
                                {
                                    matrixClassificationsText.find(
                                        ({ id }) =>
                                            id === cardInfo.classifications
                                    )?.text
                                }
                            </p>
                        )}
                    </div>
                    {cardInfo.iso3c !== "WLD" && (
                        <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] flex flex-col gap-[0.5rem] text-xs text-gray-800 mt-[1rem]">
                            <p tw="font-regular">Income classification</p>
                            <div
                                css={[
                                    tw`rounded flex gap-2 font-bold items-center max-w-max px-1`,
                                    [
                                        "low_emissions/high_income",
                                        "high_emissions/middle_income",
                                        "high_emissions/high_income"
                                    ].includes(
                                        `${cardInfo.classifications}/${cardInfo.income}`
                                    )
                                        ? tw`text-white`
                                        : tw`text-orange-800`
                                ]}
                                style={{
                                    background:
                                        matrixClassificationsIncomeBoxes.find(
                                            ({ text }) =>
                                                text ===
                                                `${cardInfo.classifications}/${cardInfo.income}`
                                        ).color
                                }}
                            >
                                <DynamicSvg
                                    iconName="IncomeTag"
                                    fill={
                                        [
                                            "low_emissions/high_income",
                                            "high_emissions/middle_income",
                                            "high_emissions/high_income"
                                        ].includes(
                                            `${cardInfo.classifications}/${cardInfo.income}`
                                        )
                                            ? "white"
                                            : theme`colors.orange.800`
                                    }
                                />
                                {
                                    matrixClassificationsIncomeText.find(
                                        ({ id }) => id === cardInfo.income
                                    )?.text
                                }
                            </div>
                        </div>
                    )}
                    {handleCumulativeEmissions()[activeScenario.id] !==
                        undefined && (
                        <div tw="rounded-[0.5rem] border border-gray-100 p-[0.5rem] flex flex-col gap-[0.5rem] text-xs text-gray-800 mt-[1rem]">
                            <h3>Total GHG emissions since 1850</h3>
                            <span tw="font-semiBold text-lg text-orange-400">
                                {formatEmissions(
                                    handleCumulativeEmissions()[
                                        activeScenario.id
                                    ],
                                    true
                                )}
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <h3 tw="font-semiBold text-xs text-gray-500 mt-[0.85rem] pl-[1rem]">
                        Emissions per sector:
                    </h3>
                    {selectedCountry.sectors.map((sector) => (
                        <button
                            tw="flex items-center justify-between p-[1.06rem 1rem] border-b border-gray-200 w-full text-sm text-gray-700 capitalize"
                            key={sector.sector + sector.iso3c}
                            name={sector.sector}
                            onClick={showSectorDetails}
                            css={[
                                blockedTransportCountries.includes(
                                    selectedCountry.iso3c
                                ) &&
                                    sector.sector === "Transport" &&
                                    tw`bg-gray-50 pointer-events-none`
                            ]}
                        >
                            <div tw="flex items-center">
                                <DynamicSvg
                                    iconName={sector.sector}
                                    size={32}
                                    fill={`${theme`colors.black`}`}
                                />
                                <h4 tw="ml-[1.2rem] text-gray-800">
                                    {sector.sector === "Energy"
                                        ? "Energy Systems"
                                        : sector.sector}
                                </h4>
                            </div>
                            <div tw="flex font-semiBold text-orange-400">
                                <p>
                                    {formatEmissions(
                                        sector[activeScenario.id],
                                        true
                                    )}{" "}
                                </p>
                                {blockedTransportCountries.includes(
                                    selectedCountry.iso3c
                                ) && sector.sector === "Transport" ? null : (
                                    <img
                                        tw="rotate-[-90deg] ml-[1rem]"
                                        alt="Arrow"
                                        src={arrow}
                                    />
                                )}
                            </div>
                        </button>
                    ))}
                </div>
            )}
            <div tw="hidden md:flex flex-col p-[1.5rem] items-center border-t border-gray-200 md:w-full md:absolute md:bottom-0">
                <Button
                    type="modal"
                    onClick={closeDetailsCard}
                    tw="max-w-[5.93rem]"
                >
                    Close
                </Button>
            </div>
            {isNotEmptyObject(subSectors) && (
                <SectorsDetails
                    isOpen={isOpen}
                    subSectors={subSectors}
                    hideSectorDetails={hideSectorDetails}
                />
            )}
        </div>
    );
};

export default Details;
